import React, { useCallback, useEffect, useState } from "react";

export default function Plaid({
  onSubmit,
  email,
  closeLoadingScreen
}) {
  const [message, setMessage] = useState(""); // State to store the message

  const createLinkToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/plaid/create_link_token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create link token: ${response.statusText}`);
      }
      const { link_token } = await response.json();
      return link_token;
    } catch (error) {
      console.error("Error creating link token:", error);
      return null; // Return null if there's an error
    }
  };

  const initializePlaid = useCallback(async () => {
    try {
      const linkToken = await createLinkToken();

      if (!linkToken) {
        console.error("Link token is undefined");
        return;
      }

      const handler = window.Plaid.create({
        token: linkToken,
        onSuccess: async (public_token, metadata) => {
          try {
            // Exchange public token for access token
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/plaid/exchange_public_token`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ public_token }),
              }
            );

            if (!response.ok) {
              throw new Error(
                `Failed to exchange public token: ${response.statusText}`
              );
            }

            const { access_token } = await response.json();

            // Retrieve transactions
            const transactionsResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/plaid/get_transactions`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ access_token, email }),
              }
            );

            if (!transactionsResponse.ok) {
              throw new Error(
                `Failed to retrieve transactions: ${transactionsResponse.statusText}`
              );
            }
            onSubmit({ email });
            closeLoadingScreen()
          } catch (error) {
            console.error("Error during onSuccess flow:", error);
          }
        },
        onExit: (err, metadata) => {
          if (err) {
            console.error("User exited Plaid Link:", err);
          }
        },
      });

      document.getElementById("link-button").onclick = () => {
        handler.open();
      };
    } catch (error) {
      console.error("Error initializing Plaid:", error);
    }
  }, []);

  useEffect(() => {
    // Load Plaid Link script
    const script = document.createElement("script");
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      initializePlaid();
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [initializePlaid]);

  return (
    <>
      <button
        className="submit-btn w-full py-2 px-4 text-white rounded-lg shadow-md focus:outline-none"
        id="link-button"
      >
        Upload automatically via Plaid
      </button>

      {/* Display success or error message */}
      {message && (
        <p className="mt-4 text-lg font-semibold text-green-600">{message}</p>
      )}
    </>
  );
}
