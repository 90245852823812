import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      // Handle form submission
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/answer/cash-advance/go-high-level/contact-us`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
          }),
        }
      );

      if (data.ok) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        toast.success(
          "Thank you for reaching out to us. We will get back to you soon."
        );
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while submitting your data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center my-10 px-4">
      <h1 className="font-bold text-[24px] sm:text-[30px] mb-6 text-center">
        Get In Touch
      </h1>

      <form
        className="border p-6 sm:p-8 pb-10 bg-white rounded-md shadow-lg w-full max-w-[700px] flex flex-col items-center"
        onSubmit={handleSubmit}
      >
        <label htmlFor="name" className="self-start font-medium mb-2">
          First Name *
        </label>
        <input
          type="text"
          id="name"
          className="w-full mb-4 p-2 border rounded-md"
          placeholder="Enter your name"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <label htmlFor="last_name" className="self-start font-medium mb-2">
          Last Name *
        </label>
        <input
          type="text"
          id="last_name"
          className="w-full mb-4 p-2 border rounded-md"
          placeholder="Enter your last name"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <label htmlFor="email" className="self-start font-medium mb-2">
          Email *
        </label>
        <input
          type="email"
          id="email"
          className="w-full mb-4 p-2 border rounded-md"
          placeholder="Enter your email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="phone" className="self-start font-medium mb-2">
          Phone *
        </label>
        <div className="flex items-center w-full mb-4">
          <PhoneInput
            country={"us"}
            enableSearch={false}
            containerClass="relative w-full max-w-[2000px]"
            inputClass="w-full p-3 border border-gray-200 rounded-md"
            buttonClass="p-3 rounded-l-md"
            dropdownClass="bg-white"
            placeholder="Enter your phone number"
            inputStyle={{ width: "100%", maxWidth: "2000px", height: "45px" }}
            containerStyle={{ width: "100%", maxWidth: "2000px" }}
            value={phoneNumber}
            onChange={setPhoneNumber}
            inputProps={{ required: true }}
          />
        </div>

        <div className="flex items-start w-full mb-4">
          <input
            type="checkbox"
            id="agreement"
            className="mr-2 h-5 w-5 text-green-500 border-gray-300 rounded"
            required
          />
          <label htmlFor="agreement" className="text-gray-700">
            By clicking ‘Apply Now’ you agree that CashAdvanceUSA may call
            (including use of automatic dialers and pre-recorded message) or
            send text/SMS message to the number provided above. You further
            agree that this consent to such contact is not required for
            transacting with CashAdvanceUSA. Std. message rates apply and you
            may opt out of receiving communications of your choice. You also
            consent to the Privacy Policy and Terms, including its arbitration
            provision.
          </label>
        </div>

        <button
          type="submit"
          className="bg-[#37ca37] w-full text-white font-semibold py-3 px-4 rounded-lg disabled:opacity-75"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Apply Now"}
        </button>
      </form>
      {/* <iframe
        src="https://apps.cashadvance-usa.com/widget/form/siOgkckdVKhk5Elx6rkR"
        // style="width:100%;height:100%;border:none;border-radius:3px"
        className="w-full min-h-[800px] border-none rounded-sm"
        id="inline-siOgkckdVKhk5Elx6rkR"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Form 0"
        data-height="771"
        data-layout-iframe-id="inline-siOgkckdVKhk5Elx6rkR"
        data-form-id="siOgkckdVKhk5Elx6rkR"
        title="Form 0"
      ></iframe>
      <script src="https://apps.cashadvance-usa.com/js/form_embed.js"></script> */}
    </div>
  );
};

export default Form;
