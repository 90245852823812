import React from "react";
import backgroundImage from "../assets/images/s1.jpeg";
import ChoseWorking from "../components/pages/ChoseWorking";
import FinancialList from "../components/pages/FinancialList";
import Ourprocess from "../components/pages/Ourprocess";
import Flexible from "../components/pages/Flexible";
import GetStarted from "../components/pages/GetStarted";
import Form from "../components/pages/Form";
import useSurveyStore from "../store/useSurveyStore";

const Financial = () => {
  const { openSurvey } = useSurveyStore();
  return (
    <>
      <div
        className="relative bg-cover bg-fixed 2xl:h-[570px] xl:h-[600px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-40"></div>

        <h1 className="relative z-10 2xl:mt-[130px] text-white text-[34px] md:text-[34px] mt-4 font-bold text-center px-4 md:px-0">
          Financial Streamlining <br />
          <p className="text-[23px] 2xl:text-[29px]">
            Boost Your Business with Working Capital Funding
          </p>
        </h1>
        <div className="absolute top-5 right-9 flex gap-4 z-20">
          <button className="bg-blue-500 text-white font-bold py-4 px-7 rounded-md">
            +1 (844) 768-2769
          </button>
          <button
            className="bg-green-500 text-white font-bold py-4 px-7 rounded-md"
            onClick={openSurvey}
          >
            Apply Now
          </button>
        </div>
      </div>

      <div className="bg-white my-7 px-3 md:px-0">
        <div className="text-center md:px-[40px] 2xl:text-[20px] 2xl:px-[460px]  lg:px-[100px] xl:px-[100px] xl:text-[21px] text-[18px] md:text-[16px]">
          <p className="tracking-tight">
            {" "}
            Accessing ample Financial Streamlining, including cash flow loans
            tailored to your small or medium-sized business, is paramount for
            achieving success. Our personalized financing options supply the
            necessary funds to effectively handle daily operations, capitalize
            on growth prospects, and navigate through cash flow hurdles.
          </p>{" "}
        </div>
        <div className="text-center my-5">
          <button
            className="border xl:text-[25px] xl:px-9 xl:py-3 bg-[#37CA37] 2xl:text-[26px] rounded-lg font-semibold text-[7px] md:text-[23px] p-3 text-white py-3 md:py-2 px-5 md:px-6 2xl:px-8 2xl:py-3"
            onClick={openSurvey}
          >
            Get Started
          </button>
        </div>
      </div>
      <ChoseWorking />
      <FinancialList />
      <Ourprocess />
      <Flexible />
      <GetStarted />
      <Form />
    </>
  );
};

export default Financial;
