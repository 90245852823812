import React from "react";
import backgroundImage from "../../assets/images/s3.jpeg";
import IconList from "./IconList";

const Ourprocess = () => {
  return (
    <>
      <div
        className="relative bg-cover  bg-fixed h-[432px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {/* Overlay with black background and opacity */}
        <div className="absolute inset-0 bg-black opacity-60"></div>

        <h1 className="relative z-10 text-white text-[32px] md:text-[42px] -mt-11 font-bold text-center px-4 md:px-0">
          Our Process
        </h1>
      </div>

      <div className="bg-white my-9 px-3 md:px-0">
        <div className="text-center 2xl:px-[520px]  2xl:text-[25px] md:px-[40px] lg:px-[100px] xl:px-[106px] text-[18px] md:text-[22px]">
          Our aim is to streamline and optimize the financing process. Here's
          what you can anticipate when selecting us for your Financial
          Streamlining requirements:
        </div>
      </div>
      <IconList />
    </>
  );
};

export default Ourprocess;
