import React from "react";
import SurveyFlow from "../components/SurveyFlow";
import useSurveyStore from "../store/useSurveyStore";
import List from "../components/pages/List";
import WhyChoose from "../components/pages/WhyChoose";
import MerchantCash from "../components/pages/MerchantCash";
import Stream from "../components/pages/Stream";
import Bestcombination from "../components/pages/Bestcombination";
import Calltoday from "../components/pages/Calltoday";
import GetStarted from "../components/pages/GetStarted";
import Form from "../components/pages/Form";
import heroVideo from "../assets/videos/hero.mp4"; // Adjust the path if necessary

const HomePage = () => {
  const { openSurvey } = useSurveyStore();

  return (
    <div>
   <div className="relative h-[40vh] sm:h-[50vh] xl:h-full 2xl:h-[60vh] overflow-hidden">
  {/* Video */}
  <video className="w-full h-full object-cover" autoPlay loop muted>
    <source src={heroVideo} type="video/mp4" />
  </video>

  {/* Semi-transparent overlay */}
  <div className="absolute top-0 left-0 right-0 bottom-0 h-full bg-black opacity-50 z-0"></div>

  {/* Text and Button */}
  <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col justify-center items-center h-full text-center text-white px-4">
    <h1 className="text-xl sm:text-2xl md:text-[33px] -mt-2 sm:-mt-4 md:-mt-[25px]">
      <b>Empower</b> your Small &
    </h1>
    <h1 className="text-lg sm:text-2xl md:text-[33px] mt-2 sm:mt-3 md:mt-[20px]">
      Medium sized <b>business</b>
      <p className="text-lg sm:text-xl md:text-[23px] font-bold mt-2 sm:mt-3 md:mt-[20px]">
        With Flexible Funding
      </p>
    </h1>
    <button
      onClick={openSurvey}
      className="bg-[#37ca37] mt-3 sm:mt-5 text-white font-bold rounded-lg w-[140px] h-[35px] sm:w-[160px] sm:h-[40px] md:w-[191px] md:h-[50px] text-[14px] sm:text-[16px] md:text-[18px]"
    >
      <span className="block text-[12px] sm:text-[16px] md:text-[22px]">Get Started</span>
    </button>
  </div>

  {/* Contact and Apply Now */}
  <div className="absolute  top-3 sm:top-5 left-1/2 transform -translate-x-1/2 md:top-5 md:left-auto md:right-3 md:transform-none  gap-2 sm:gap-4 z-50 w-full md:w-auto md:justify-end hidden sm:flex">
  <a
    href="tel:+18447682769"
    className="bg-blue-500 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-md text-center text-[12px] sm:text-[14px] md:text-[15px]"
  >
    +1 (844) 768-2769
  </a>
  <span
    onClick={openSurvey}
    className="bg-green-500 text-white font-bold cursor-pointer py-2 px-4 sm:py-3 sm:px-6 rounded-md text-center text-[12px] sm:text-[14px] md:text-[15px]"
  >
    Apply Now
  </span>
</div>

</div>



      {/* Other Components */}
      <List />
      <WhyChoose />
      <MerchantCash />
      <Stream />
      <Bestcombination />
      <Calltoday />
      <GetStarted />
      <Form />
    </div>
  );
};

export default HomePage;
