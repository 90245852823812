import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ThankYouPage from './pages/ThankYouPage';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import Analytics from './pages/Analytics';
import Insights from './pages/Insights';
import Conversions from './pages/Conversions';
import Sessions from './pages/Sessions';
import './index.css';
import './custom.css';
import Layout from './components/layout/Layout';
import ContactUsPage from './pages/ContactUsPage';
import Financial from './pages/Financial';
import EquipmentFinancing from './pages/EquipmentFinancing';
import MerchantCashAdvance from './pages/MerchantCashAdvance.jsx';
import Plaid from './components/Plaid';
const App = () => {
  return (

    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/plaid" element={<Plaid/>} />
          <Route path="/financial" element={<Financial />} />
          <Route path="/equipment" element={<EquipmentFinancing />} />
          <Route path="/merchant" element={<MerchantCashAdvance />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/admin/analytics/dashboard" element={<Analytics />} />
          <Route path="/admin/analytics/insights" element={<Insights />} />
          <Route path="/admin/analytics/conversions" element={<Conversions />} />
          <Route path="/admin/analytics/sessions" element={<Sessions />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/login" element={<AdminLogin />} />

        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
