import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import useSurveyStore from "../store/useSurveyStore";

const useFormState = () => {
  // Define the initial state for all form fields
  const [state, setState] = useState({
    desiredFunding: null,
    officialBusinessName: null,
    typeOfBankAccount: null,
    businessActivity: null,
    monthlyRevenue: null,
    industry: null,
    firstName: null,
    lastName: null,
    phoneNumberAndEmail: null,
    phoneNumber: null,
    email: null,
    dateOfBirth: null,
    ficoScore: null,
    socialSecurityNumber: null,
    homeAddress: null,
    utilizationOfFunds: null,
    legalStructure: null,
    owns51Percent: null,
    ownershipPercentage: null,
    businessInfo: null,
    liensOrJudgments: null,
    defaultedCashAdvance: null,
    filedBankruptcy: null,
    currentCashAdvance: null,
    lenderName: null,
    currentBalance: null,
    paymentFrequency: null,
    paymentAmount: null,
    hasCollateral: null,
    position1: null,
    propertyAddress: null,
    assetType: null,
    yearAcquired: null,
    purchasePrice: null,
    currentValue: null,
    loanBalance: null,
    otherLenders: null,
    lenderName1: null,
    titleHolders: null,
    bankStatements: null,
    utmContent: null,
    utmMedium: null,
    plaid: null,
  });

  const { isQualifiedLead, setIsQualifiedLead } = useSurveyStore();

  // Function to handle the form submission
  const handleSubmit = useCallback(
    async (updatedState) => {
      // const url = isQualifiedLead ? QualifiedLeadURL : UnQualifiedLeadURL;
      try {
        state.email = state.phoneNumberAndEmail.email
        state.phoneNumber = state.phoneNumberAndEmail.phoneNumber
        console.log('process.env.REACT_APP_API_URL on useFormState', process.env.REACT_APP_API_URL);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/answer/cash-advance/go-high-level`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(state),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const isQualified = data.isQualified;
        setIsQualifiedLead(isQualified);
        isQualified ? fireQualifiedLeadPixel() : fireUnqualifiedLeadPixel();
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("An error occurred while submitting your data.");
      }
    },
    [state]
  );

  const fireQualifiedLeadPixel = () => {
    // Insert the first conversion tracking script for qualified lead
    const script1 = document.createElement('script');
    script1.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";
    script1.async = true;
    document.head.appendChild(script1);

    // Insert the second tracking script for qualified lead
    const script2 = document.createElement('script');
    script2.src = "https://track.cashadvance-usa.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";
    script2.async = true;
    document.head.appendChild(script2);

    // Meta Pixel Code
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Track lead using Meta Pixel
    fbq('init', '8242082332506292');
    fbq('track', 'lead');

    // Add noscript fallback for Meta Pixel
    const noscriptElement = document.createElement('noscript');
    const imgElement = document.createElement('img');
    imgElement.height = "1";
    imgElement.width = "1";
    imgElement.style.display = "none";
    imgElement.src = "https://www.facebook.com/tr?id=8242082332506292&ev=lead&noscript=1";
    noscriptElement.appendChild(imgElement);
    document.body.appendChild(noscriptElement);

    // Mark this pixel as fired
    // setPixelFired((prev) => ({ ...prev, qualifiedLead: true }));
  };
  /* global fbq */

  const fireUnqualifiedLeadPixel = () => {
    // Insert the first conversion tracking script for unqualified lead
    const script1 = document.createElement('script');
    script1.src = "https://track.cashadvance-usa.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=unqualified_lead";
    script1.async = true;
    document.head.appendChild(script1);

    // Insert the second tracking script for unqualified lead
    const script2 = document.createElement('script');
    script2.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=unqualified_lead";
    script2.async = true;
    document.head.appendChild(script2);

    // Mark this pixel as fired
    // setPixelFired((prev) => ({ ...prev, unqualifiedLead: true }));
  };

  // Function to update the form state
  const updateState = (field) => (value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (
      state.phoneNumberAndEmail ||
      state.currentCashAdvance ||
      state.titleHolders
    ) {
      handleSubmit(state);
    }
  }, [state, handleSubmit]);

  return [state, updateState, isQualifiedLead];
};

export default useFormState;
