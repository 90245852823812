import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import useSurveyStore from "../../store/useSurveyStore";
const data = [
  {
    id: 1,
    name: "Fast Results",
    para: "Completing your application only requires 5-10 minutes, and you can expect offers within just a few hours.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
  {
    id: 2,
    name: "Flexible Terms",
    para: "We aid you in effortlessly comparing your options and consistently strive to secure the most favorable terms for you.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
  {
    id: 3,
    name: "Expert Support",
    para: "Our advisors will ensure that the product you ve selected aligns perfectly with your business requirements.",
    btn: "Apply Now",
    icon: <FaLongArrowAltRight className="mt-1" />,
  },
];

const Card = () => {
  const { showSurvey, openSurvey } = useSurveyStore();
  return (
<div className="flex flex-wrap items-center my-6 justify-center gap-4 sm:gap-6 2xl:gap-7">
  {data.map((item) => (
    <div
      key={item.id}
      className="card h-[30vh] w-[45vh]  md:h-[20vh] xl:h-[360px] 2xl:h-[380px]  sm:w-[20vw] md:w-[60vw] xl:w-[400px] 2xl:w-[390px] bg-[#027BC3] p-3 sm:p-4 md:p-5 2xl:p-1 rounded-lg shadow-lg text-center"
    >
      <h1 className="text-[20px] sm:text-[22px] md:text-[24px] xl:text-[27px] text-white font-bold mb-2 sm:mb-3 md:mb-4 2xl:mb-4 sm:my-5 2xl:my-10">
        {item.name}
      </h1>
      <p className="text-white text-[16px] sm:text-[18px] md:text-[20px] p-2 sm:p-3 md:p-5">
        {item.para}
      </p>
      <button
        onClick={openSurvey}
        className="text-white font-bold px-2 py-1 sm:px-3 sm:py-2 md:px-4 md:py-2 rounded text-[14px] sm:text-[16px] md:text-[18px] flex items-center mx-auto gap-2 mt-2 sm:mt-4 md:mt-5"
      >
        {item.btn}
        {item.icon}
      </button>
    </div>
  ))}
</div>



  );
};

export default Card;
