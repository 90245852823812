import React from "react";

import fiveTenMinutes from "../../assets/images/apply-in-5-10.webp"; // Adjust the path if necessary
import decisionAsFastAs from "../../assets/images/decision-as-fast-as.webp"; // Adjust the path if necessary
import fundedAsFastAs from "../../assets/images/funded-as-fast-as.webp"; // Adjust the path if necessary
import receiveBetween from "../../assets/images/receive-between.webp"; // Adjust the path if necessary

const List = () => {
  const data = [
    {
      id: "1",
      name: "Apply in",
      description: "5-10 minutes",
      icon: (
        <img
          src={fiveTenMinutes}
          alt="apply in 5-10 minutes"
          className="w-[75-px] h-[75px]"
        />
      ),
    },
    {
      id: "2",
      name: "Decision as fast as",
      description: "3 hours",
      icon: (
        <img
          src={decisionAsFastAs}
          alt="Decision as fast as"
          className="w-[75-px] h-[75px]"
        />
      ),
    },
    {
      id: "3",
      name: "Funded as fast as",
      description: "24 hours",
      icon: (
        <img
          src={fundedAsFastAs}
          alt="Funded as fast as"
          className="w-[75-px] h-[75px]"
        />
      ),
    },
    {
      id: "4",
      name: "Receive between",
      description: "$20k to $5m",
      icon: (
        <img
          src={receiveBetween}
          alt="Receive between"
          className="w-[75-px] h-[75px]"
        />
      ),
    },
  ];

  return (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  2xl:grid-cols-4 2xl:py-5 2xl:px-[130px]">
        {data.map((item) => (
          <div
            key={item.id}
            className="flex flex-col items-center  justify-center m-6 md:m-8 p-2 rounded-md text-center"
          >
            <span className="text-[65px] md:text-[75px] mb-4">{item.icon}</span>
            <p className="text-lg md:text-xl mb-4 md:mb-5">{item.name}</p>
            <p className=" text-xl md:text-2xl font-bold text-sky-600">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
