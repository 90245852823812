import React from "react";
import useSurveyStore from "../../store/useSurveyStore";

const GrowToget = () => {
  const { openSurvey } = useSurveyStore();
  return (
    <>
      <div className="flex justify-center  bg-blue-500 p-6 sm:p-8 md:p-11">
        <div className="text-[18px] sm:text-[20px] text-white max-w-[100%] sm:max-w-[70rem]">
          <h1 className="text-center text-[20px] sm:text-[23px] font-bold">
            Let's Grow Together
          </h1>
          <p className="p-2 text-[16px] sm:text-[18px] sm:px-[75px] text-center">
            At CashAdvanceUsa, your triumph is our triumph. We're dedicated to
            fueling business prosperity and are steadfast in offering the
            financial backing necessary for soaring to greater heights. Come
            aboard as we embark on the path to a brighter future for your
            business, and together, we'll realize remarkable achievements.
          </p>
          <div className="flex justify-center">
            <button
              className="rounded-md bg-[#37CA37] py-3 px-5 sm:py-4 sm:px-7 text-[18px] sm:text-[20px] font-bold"
              onClick={openSurvey}
            >
              Click to Sign Up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowToget;
