// src/store/useSurveyStore.js
import { create } from 'zustand';
const useSurveyStore = create((set) => ({
  isQualifiedLead: null, // Initial value
  setIsQualifiedLead: (value) => set({ isQualifiedLead: value }),

  showSurvey: false,
  openSurvey: () => set({ showSurvey: true }),
  closeSurvey: () => set({ showSurvey: false }),

  // percentageProcess state and functions
  percentageProcess: 0,
  setPercentageProcess: (percentage) => set({ percentageProcess: percentage }),
  updatePercentageProcess: (state) => {
    const totalFields = Object.keys(state).length;
    const filledFields = Object.values(state).filter(
      (value) => value !== null && value !== ""
    ).length;
    const percentage = (filledFields / totalFields) * 100;
    set({ percentageProcess: percentage });
  },
  finishedSurvey: false,
  setFinishedSurvey: (finishedSurvey) => set({ finishedSurvey }),

  reset: () =>
    set({ showSurvey: false, percentageProcess: 0, finishedSurvey: false }),
}));

export default useSurveyStore;
