import React, { useEffect, useState, useCallback } from "react";
import QuestionTemplate from "./questionnaires/QuestionTemplate";
import Plaid from "./Plaid";
import useFormState from "../hooks/useFormState";
import useSurveyStore from "../store/useSurveyStore";

// Reusable LoadingStep component
const LoadingStep = ({ message }) => (
    <div className="flex flex-col items-center justify-center gap-1 min-h-80 w-full">
        <div role="status" aria-label={message}>
            <svg
                className="w-8 h-8 stroke-[#027BC2] animate-spin"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_9023_61563)">
                    <path
                        d="M14.6437 2.05426C11.9803 1.2966 9.01686 1.64245 6.50315 3.25548C1.85499 6.23817 0.504864 12.4242 3.48756 17.0724C6.47025 21.7205 12.6563 23.0706 17.3044 20.088C20.4971 18.0393 22.1338 14.4793 21.8792 10.9444"
                        stroke="stroke-current"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                    ></path>
                </g>
                <defs>
                    <clipPath id="clip0_9023_61563">
                        <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
            <span className="sr-only">{message}</span>
        </div>
        <div className="text-lg">{message}</div>
    </div>
);

// Flash message with continue button
const FlashMessageWithContinue = ({ onSubmit, message, email, closeLoadingScreen }) => {

    //! return only Bank statement without Plaid integration
    //todo need to remove comment when we start to use Plaid integration
    return <div className="flex flex-col items-center justify-center gap-7 min-h-80 w-full">

        {/* <div className="text-red-600 font-bold text-2xl">
            MISSING BANK INFO
            <p className="text-base text-red-400">
                Please login via Plaid.
            </p>
        </div>
        <Plaid email={email} onSubmit={onSubmit} closeLoadingScreen={closeLoadingScreen} /> */}
        <button
            className="submit-btn w-full py-2 px-4 text-white rounded-lg shadow-md focus:outline-none"
            onClick={closeLoadingScreen}
        >
            Upload manually bank statements files
        </button>
        {/* Display success or error message */}
        {message && (
            <p className="mt-4 text-lg font-semibold text-green-600">{message}</p>
        )}
    </div>
}


export default function LoadingScreenWithContinue({ closeLoadingScreen, onSubmit, companyName, financingReason, email }) {
    const { isQualifiedLead } = useSurveyStore();

    const [message, setMessage] = useState(""); // State to store the message
    const [loadingStep, setLoadingStep] = useState(0); // Manage loading steps

    const title = `We've identified promising financing options for ${companyName} that could aid ${financingReason}.`;
    const description = "To move forward, please use Plaid to securely log into your account or upload a PDF of your bank statements using the link below.";

    useEffect(() => {
        // Step durations in milliseconds
        const stepsDuration = [1000, 1500, 2000];
        let timer;

        // Set up a single timer that progresses through all steps
        timer = setTimeout(() => {
            if (loadingStep < 3) {
                setLoadingStep(prev => prev + 1);
            }
        }, stepsDuration[loadingStep]);

        // Cleanup timer on component unmount
        return () => clearTimeout(timer);
    }, [loadingStep]);

    // Display loading steps
    if (loadingStep < 3) {
        const messages = ["Processing...", "Submitting Request...", "Retrieving Options..."];
        return (
            <QuestionTemplate
                title={title}
                description={description}
                hasOptionsButtons
            >
                <LoadingStep message={messages[loadingStep]} />
            </QuestionTemplate>
        );
    }

    // Show flash message with continue button and don't disappear until user clicks "Continue"
    if (loadingStep === 3) {
        if (isQualifiedLead) {
            // setMessage("Thank you for choosing us! We will now proceed with your request.");

            //! return only Bank statement without Plaid integration
            return (
                < QuestionTemplate
                    title={title}
                    description={'To move forward, please Upload a PDF of your bank statements using the link below.'}
                    hasOptionsButtons
                >
                    <FlashMessageWithContinue message={message} closeLoadingScreen={closeLoadingScreen} email={email} />
                </QuestionTemplate >
            );

            //! return with Plaid integration
            // return (
            //     < QuestionTemplate
            //         title={title}
            //         description={description}
            //         hasOptionsButtons
            //     >
            //         <FlashMessageWithContinue message={message} closeLoadingScreen={closeLoadingScreen} onSubmit={onSubmit} email={email} />
            //     </QuestionTemplate >
            // );
        } else {
            // setMessage("We are unable to provide financing options at this time. Please try again later or contact our support team.");
            return (
                < QuestionTemplate
                    title={title}
                    description={'To move forward, please Click on Submit and proceed .'}
                    // hasOptionsButtons
                    onSubmit={() => window.location.href = 'https://enessedleasant.com/8d9f90b6-acd5-4a36-890f-9b61df1f57a0'}
                >

                </QuestionTemplate >
            );
        }

    }

    return null; // Prevent any unnecessary rendering
}
