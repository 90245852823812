import React from "react";
import SurveyFlow from "../SurveyFlow";
import useSurveyStore from "../../store/useSurveyStore";
const data = [
  {
    id: 1,
    name: "Step 1. Complete the application",
    para: "The application is very simple, and you can apply online or over the phone.",
  },
  {
    id: 2,
    name: "Step 2.  Get a decision",
    para: "Your dedicated loan advisor will review your options with you.",
  },
  {
    id: 1,
    name: "Step 3. Receive your funds​",
    para: "Complete the online checkout and receive your funds as soon as the same day.",
  },
];
const Bestcombination = () => {
  const { showSurvey, openSurvey } = useSurveyStore();

  return (
    <>
      <div className="flex justify-center my-8 sm:my-11">
        <div className="text-[36px] sm:text-[36px] md:text-[47px] text-center font-bold">
          <h1>The best combination of</h1>
          <h1>
            <span className="text-green-400">speed</span> and{" "}
            <span className="text-green-400">service</span>.
          </h1>
          <h1>And it is easy to get started.</h1>
        </div>
      </div>

      <div className="mx-auto  mt-7 sm:mt-7 lg:mt-7 px-4 sm:px-6 lg:px-8 w-full sm:w-[80%] lg:w-[60%]">
        {data.map((item) => (
          <div key={item.id} className="mb-5 2xl:-ml-[80px] xl:-ml-[230px]">
            <h1 className="font-bold text-[20px] sm:text-[30px] md:text-[30px] 2xl:text-[30px] xl:text-[30px]">
              {item.name}
            </h1>
            <p className="text-base sm:text-lg font-semibold">{item.para}</p>
          </div>
        ))}
        <div className="flex justify-center my-11 mt-6 sm:mt-8 lg:mt-12">
          <button
            onClick={openSurvey}
            className="border text-lg sm:text-xl md:text-2xl font-semibold p-3 sm:p-4 md:px-5 text-white bg-[#37ca37] rounded-lg"
          >
            Apply Now
          </button>
        </div>
      </div>
    </>
  );
};

export default Bestcombination;
