import React from "react";
import backgroundImage from "../assets/images/66325f1fce48d106e28f634e.jpeg";
import backgroundImages from "../assets/images/6631093b2ed07574a9e912c4.jpeg";
import backgroundImage2 from "../assets/images/66327457d5653d0d5d79e3b0.jpeg";
import Card from "../components/pages/Card";
import GrowToget from "../components/pages/GrowToget";
import GetStarted from "../components/pages/GetStarted";
import Form from "../components/pages/Form";
import Cardtwo from "../components/pages/Cardtwo";
import useSurveyStore from "../store/useSurveyStore";

const AboutUsPage = () => {
  const { openSurvey } = useSurveyStore();
  return (
    <>
      <div className="relative">
        <div
          className="bg-cover bg-fixed xl:h-[560px] bg-center flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
          <h1 className="text-white text-4xl mt-[90px] font-bold relative z-10">
            About Us
          </h1>
        </div>

        <div className="bg-white">
          <div className="text-center  2xl:w-full 2xl:px-[400px] xl:px-[110px] 2xl:py-10 p-4 md:p-4 text-[18px] md:text-[10px]  2xl:text-[21px] xl:text-[21px]  lg:px-[100px] ">
            <p className="tracking-tight">
              At CashAdvanceUsa, we trust in the capacity of small and
              medium-sized businesses to shape communities and stimulate
              economic advancement. Our aim is to embolden these enterprises
              through personalized financial solutions, igniting their triumph
              and propelling them towards their utmost potential.
            </p>
          </div>
        </div>
        <div className="absolute top-5 right-9 flex gap-4 z-20">
          <button className="bg-blue-500 text-white font-bold py-4 px-7 rounded-md">
            +1 (844) 768-2769
          </button>
          <button
            className="bg-green-500 text-white font-bold py-4 px-7 rounded-md"
            onClick={openSurvey}
          >
            Apply Now
          </button>
        </div>
      </div>

      <div>
        <div
          className="bg-cover bg-fixed h-[410px] flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${backgroundImages})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
          <h1 className="text-white text-[40px] -mt-9 font-bold relative z-10">
            Our Story
          </h1>
        </div>

        <div className="bg-white text-wrap px-4 py-6 md:px-16 md:py-12 xl:px-[115px] lg:px-20 lg:py-16">
          <div className="text-center text-[18px] xl:text-[20px] 2xl:px-[340px]  md:text-[18px]">
            <p className="tracking-tight">
              CashAdvanceUsa stands as a premier private lending firm. Our
              inception stemmed from a collective vision among committed
              financial experts: to close the divide between conventional
              banking entities and the distinctive financial requisites of small
              and medium-sized enterprises.
            </p>

            <p className="mt-4 tracking-tight">
              Through the years, we've evolved into a relied-upon ally for
              myriad business proprietors throughout the United States. Our
              steadfast dedication to comprehending our clients' hurdles and
              offering adaptable, personalized resolutions has distinguished us
              within the industry.
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="text-center py-6">
          <h1 className="text-4xl font-bold">Our Services</h1>
          <p className="text-xl p-3  tracking-tight">
            At CashAdvanceUsa, we provide an extensive array of financial
            services crafted to bolster businesses at every phase of their
            growth trajectory:
          </p>
        </div>
        <Cardtwo />
      </div>

      <div>
        <div
          className="bg-cover bg-fixed h-[460px] flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${backgroundImage2})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
          <h1 className="text-white text-4xl font-bold relative z-10">
            Our Approach
          </h1>
        </div>

        <div className="bg-white px-4 py-2 md:px-16 md:py-4 lg:px-[120px] xl:px-[40px]">
          <div className="text-center 2xl:px-[380px] p-2 md:p-4 text-[16px] 2xl:text-[21px] lg:text-[19px] text-wrap">
            <p className="tracking-tight">
              We prioritize cultivating enduring, solid relationships with our
              clients. We invest time to grasp your distinct business
              objectives, challenges, and financial needs. This allows us to
              create customized financial solutions tailored to drive success.
            </p>
            <h1 className="text-center text-[17px] md:text-[18px] lg:text-[19px] p-2 md:p-3">
              We take pride in our:
            </h1>
          </div>
        </div>

        <Card />
        <GrowToget />
        <br />
        <GetStarted />
        <Form />
      </div>
    </>
  );
};

export default AboutUsPage;
