import React, { useState } from "react";
import QuestionTemplate from "./QuestionTemplate"; // Adjust the path as necessary

const BankStatementUpload = ({ onSubmit, email: emailProp, onGoBack }) => {
  const [files, setFiles] = useState({
    firstMonth: null,
    secondMonth: null,
    thirdMonth: null,
    fourthMonth: null,
  });

  const [email, setEmail] = useState(emailProp || "");

  const handleFileChange = (event, month) => {
    const file = event.target.files[0];
    if (file && isValidFileType(file)) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [month]: file,
      }));
    } else {
      alert("Please upload a valid file (image, PDF, or DOC).");
    }
  };

  const isValidFileType = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return validTypes.includes(file.type);
  };

  const uploadDocuments = async () => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      if (files.firstMonth) formData.append("contact.bank_statement_1st_month", files.firstMonth);
      if (files.secondMonth) formData.append("contact.bank_statement_2nd_month", files.secondMonth);
      if (files.thirdMonth) formData.append("contact.bank_statement_3rd_month", files.thirdMonth);
      if (files.fourthMonth) formData.append("contact.bank_statement_4th_month", files.fourthMonth);

      const response = await fetch(
        `https://hooks.zapier.com/hooks/catch/19731238/2hwbdkm/`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleUpload = () => {
    if (!files.firstMonth) {
      alert("Please upload the Bank Statement for the 1st Month.");
      return;
    }

    if (!email) {
      alert("Please re-confirm your email.");
      return;
    }
    uploadDocuments();

    onSubmit({ files, email });

    // requestAnimationFrame(() => {
    //   handleFinalSubmit();
    // });
  };

  const renderFileInput = (id, label, month) => (
    <div className="text-left">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <label
        htmlFor={id}
        className={`cursor-pointer mt-1 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg h-24 ${files[month] ? "bg-green-50 border-green-400" : ""
          }`}
      >
        <input
          id={id}
          type="file"
          className="hidden"
          onChange={(e) => handleFileChange(e, month)}
          accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
        />
        <div className="text-center">
          {files[month] ? (
            <>
              <svg
                className="mx-auto h-8 w-8 text-green-600"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-sm text-green-600 truncate">
                {files[month].name}
              </span>
            </>
          ) : (
            <>
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span className="text-sm text-gray-500">{label}</span>
            </>
          )}
        </div>
      </label>
    </div>
  );

  return (
    <QuestionTemplate
      title="We Need You To Submit Your Last 4 Bank Statements"
      subtitle="To enhance your qualification for the cash advance, providing your bank statements will be beneficial."
      onSubmit={handleUpload}
      onGoBack={onGoBack}
    >
      <div className="mt-6 text-left">
        <label
          htmlFor="email-confirm"
          className="block text-sm font-medium text-gray-700"
        >
          Please Re-Confirm Your Email *
        </label>
        <input
          type="email"
          id="email-confirm"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          required
        />
      </div>
      <div className="space-y-4">
        {renderFileInput(
          "file-input-1",
          "Bank Statement 1st Month *",
          "firstMonth"
        )}
        {renderFileInput(
          "file-input-2",
          "Bank Statement 2nd Month",
          "secondMonth"
        )}
        {renderFileInput(
          "file-input-3",
          "Bank Statement 3rd Month",
          "thirdMonth"
        )}
        {renderFileInput(
          "file-input-4",
          "Bank Statement 4th Month",
          "fourthMonth"
        )}
      </div>
    </QuestionTemplate>
  );
};

export default BankStatementUpload;
