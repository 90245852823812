import React from 'react';
import { FaLongArrowAltRight } from "react-icons/fa";

const data = [
  { id: 1, name: 'Financial Streamline', para: 'Secure the necessary funds to oversee daily operations, meet payroll demands, and invest in the future of your business.', btn: 'Apply Now',icon:<FaLongArrowAltRight/>, image: '3.png' },
  { id: 2, name: 'Equipment Financing', para: 'Obtain vital tools and technology to maintain competitiveness in your industry without immobilizing valuable capital.', btn: 'Apply Now',icon:<FaLongArrowAltRight/> ,image: '2.png'},
  { id: 3, name: 'Merchant Cash Advances', para: 'Transform your forthcoming credit card sales into instant cash, ensuring swift access to funds precisely when you need them most', btn: 'Apply Now',icon:<FaLongArrowAltRight/>,image: '1.png' },
];

const Cardtwo = () => {
  return (
<div className="flex flex-wrap items-center my-6 justify-center gap-4 sm:gap-6 2xl:gap-7">
  {data.map((item) => (
    <div
      key={item.id}
      className="card h-[30vh] w-[45vh]  md:h-[20vh] xl:h-[460px] 2xl:h-[480px] 2xl:py-7  sm:w-[20vw] md:w-[60vw] xl:w-[400px] 2xl:w-[390px] bg-[#027BC3] p-3 sm:p-4 md:p-5 2xl:p-2 rounded-lg shadow-lg text-center"
    >
       <img src={item.image} alt={item.name} className="h-24 w-24 mx-auto mb-3 object-cover rounded" />

      <h1 className="text-[20px] sm:text-[22px] md:text-[24px] xl:text-[27px] text-white font-bold mb-2 sm:mb-3 md:mb-4 2xl:mb-4 sm:my-5 2xl:my-10">
        {item.name}
      </h1>
      <p className="text-white text-[16px] sm:text-[18px] md:text-[20px] p-2 sm:p-3 md:p-5">
        {item.para}
      </p>
      <button
       
        className="text-white font-bold px-2 py-1 sm:px-3 sm:py-2 md:px-4 md:py-2 rounded text-[14px] sm:text-[16px] md:text-[18px] flex items-center mx-auto gap-2 mt-2 sm:mt-4 md:mt-5"
      >
        {item.btn}
        {item.icon}
      </button>
    </div>
  ))}
</div>
  );
};

export default Cardtwo;
